/* _____ TYPOGRAPHY _____ */

  /* ~~ Dashboard ADMIN ~~ */

.dashAdminItems {
  text-decoration: none;
  text-align: center;
}



 /* ~~ Dashboard ADMIN ~~ */
  .dashDentistCont h2{
    font-size: var(--fs-21);
  }









/* _____ DESIGN _____ */

  /* ~~ Dashboard ADMIN ~~ */
.dashAdminPage {
  width: 100%;
  /* height: var(--page-height-noscroll); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dashAdminPage .sectionHeadder{
  margin-bottom: 5rem;
}

.dashAdminCont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
}


.dashAdminItems {
  width: 200px;
  height: 80px;
  margin:0 1rem;
  background-color: var(--clr-main);
  border-radius: 8px;
  border: 2px solid var(--clr-accent);
  box-shadow: var(--shadow-btn);

  display: flex;
  align-items: center;
  justify-content: center;
}

.dashAdminItems:hover,
.dashAdminItems:focus {
  background-color: var(--clr-75opac);
  text-decoration: underline 2px solid var(--clr-accent);
}

.dashAdminForm {
  display: flex;
  flex-direction: column;
  width: 25%;
}

.dashAdminForm .formLabel {
  margin-bottom: 0.5rem;
}

.dashAdminForm .formInput {
  margin-bottom: 1rem;
}

.dashAdminForm button {
  width: 120px;
  height: 40px;
  background-color: var(--clr-accent);
  box-shadow: var(--shadow-btn);
  border-radius: 100px;
  align-self: flex-end;
  cursor: pointer;
}

.dashAdminForm button:hover,
.dashAdminForm button:focus {
  background-color: var(--clr-main);
  border: 2px solid var(--clr-accent);
  text-decoration: underline solid 2px var(--clr-accent);
}

.dashAdminForm button:active {
  background-color: var(--clr-accent);
  border: none;
}




  /* ~~ Dashboard DENTIST ~~ */

.dashDentistPage {
  width: 100%;
  /* min-height: var(--page-height-noscroll); */
  padding: 6rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dashDentistPage h1{
  margin-bottom: 4rem;
}

.dashDentistCont {
  width: max(750px, 80%); 
    /* 100% @MQ 750px */
  /* display: flex; */
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.dashDentistCont h2{
  margin-bottom: 2rem;
}

.pasientCont {
  width: 100%;
  padding: 1rem 2rem;
  background-color: var(--fc-darkmode);
  border: 2px solid var(--clr-accent);
  box-shadow: var(--shadow-btn);
  border-radius: 8px;
  margin-bottom: 2rem;

  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.pasientComplete {
  opacity: 45%;
}

.pasientCont p:not(:last-of-type) {
  margin-right: 3.5rem;
}

.pasientVidLink {
  cursor: pointer;
}

.client-taken{
  cursor: not-allowed;
}











/* _____ MOBILE _____ */

@media (max-width: 750px){
  
  /* ~~ Dashboard ADMIN ~~ */

  .dashAdminPage {
    padding: 5rem 0;
  }

  .dashAdminCont {
    flex-direction: column;
  }
  .dashAdminItems {
    margin:1rem 0;
  }

  .dashAdminForm {
    width: 200px;
  }


    /* ~~ Dashboard ADMIN ~~ */

    .dashDentistCont {
      width: 100%; 
    }

    .pasientCont {
      flex-direction: column;
      gap:2rem;
    }
    .pasientCont p {
      margin-right: 0;

    }
}