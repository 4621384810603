/* The functional CSS of the reel itself */

/* _____ TYPOGRAPHY _____ */

.empReelNames {
    font-size: var(--fs-18);
    font-weight: var(--fw-5);
    color: var(--fc);
    text-align: center;
}

.empReelTxt {
    font-size: var(--fs-16);
    font-weight: var(--fw-4);
    color: var(--fc);
    text-align: center;
    width: 100%;
}






/* _____ DESIGN _____ */
#tannlegeReel {
  position: relative;
}

.reelCont {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}


.reel {
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
}

.empReelCont {
    width: 300px;
    /* height: 400px; */
    display: flex;
    flex-direction: column;
    align-items: center;
}


.empReelImg {
  display: flex;
    width: 250px;
    height: 250px;
    margin-bottom: 2rem;
    background-color: var(--clr-main );
    border: 5px solid var(--clr-accent);
    border-radius: 300px;
    box-shadow: var(--shadow-img);
    object-fit: contain;
    /* object-position: top; */
}


.empReelNames {
    margin-bottom: 1rem;
}

.arrowCont {
    height: 0px;
    width: 300px;
    /* 
    height: 250px; */

    position: absolute;
    top: 30%;
    background-color: red;
}

.dotsCont {
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:10px;
    position: absolute;
    bottom: -30px;
}

.dentistReelDot {
    height: clamp(10px, 1.125vw, 30px);
    width: clamp(10px, 1.125vw, 30px);
    background-color: var(--clr-accent);
    border-radius: 100px;
    opacity: 80%;
    box-shadow: var(--shadow-btn);
}

.dentistReelDotCurrent{
    opacity: 100%;
    height: clamp(20px, 1.25vw, 40px);
    width: clamp(20px, 1.25vw, 40px);
}

.prev, .next {
    font-size: 60px;
    width: fit-content;
    color: var(--clr-accent);
    padding: 16px;

    position: absolute;
    top: 50%;
    translate: 0 -50%;
    transition: 0.6s ease;
    user-select: none;
    
    scale: 0.9;
}
.prev {
    left:-110%;
}
.next {
    right: -110%;
}

.prev:hover,.prev:hover,
.next:hover,.next:hover {
    cursor: pointer;
    scale: 1.125;
}



/* _____ MEDIA QUERIES _____ */



@media(max-width: 1000px) {
    .reelCont { width: 100%; }

    .empReelCont { padding-top: 3rem;}

    .arrowCont { 
        width: 100vw; 
        display:block;
        top: 42%;
    } 

    .prev, .next {
        transition: none;
    }

    .prev { left: 120px; }

    .next { right: 120px; }

    .dotsCont {
        bottom: -110px;
    }

    .dentistReelDot {
        height: clamp(7px, 1.125vw, 30px);
        width: clamp(7px, 1.125vw, 30px);
    }
    
    .dentistReelDotCurrent{
        height: clamp(15px, 1.25vw, 40px);
        width: clamp(15px, 1.25vw, 40px);
    }
    
}

@media(max-width: 700px) {
    .prev { left: 1rem; }

    .next { right: 1rem; }

    .dotsCont {
        bottom: -10px;
    }
    
    .empReelCont {
        padding-top: 1rem;
    }

    
}

@media(max-width: 400px) {
    .reelCont { width: 100%;}

    .empReelCont { width: 250px; }
    .empReeltext { width: 250px; }


    .prev, .next {
        /* opacity: 0; */
        /* height: 250px; */
        /* width: 50%; */
        padding: 0;
        /* right: 0; */
    }

    .arrowCont {
        width: 100vw;
        /* top: 0; */
        translate: 0 50%;
    }
}
