    /* ___ TYPOGRAPHY ___ */
.sectionHeadder {
    font-size: var(--fs-44);
}

h1, h2 {
    font-size: var(--fs-33);
}

h3 {
    font-size: var(--fs-21);
    font-weight: var(--fw-5);
    /* sjekk font weight for h1 og h2 */
    text-decoration: underline;
}

.articleCard h3 {
    font-size: var(--fs-18);
    font-weight: var(--fw-5);
    color: var(--fc);
}

.articleCardText {
    font-size: var(--fs-18);
    font-weight: var(--fw-3);
    line-height: 1.25rem;
    color: var(--fc);
}

.lesMerBtn {
    font-size: var(--fs-18);
    font-weight: var(--fw-4);
    color: var(--fc);    
}


.employeeModalCont h3{
    font-size: var(--fs-21);
    font-weight: var(--fw-5);
    color: var(--fc);
    text-shadow: var(--shadow-txt);
}

.employeeModalCont p{
    font-size: var(--fs-18);
    font-weight: var(--fw-4);
    color: var(--fc);
    text-shadow: var(--shadow-txt);
}



    /* ___ DESIGN ___ */

.articlesPage {
    height: fit-content;
    width: 100%;
    padding: 0px var(--padding-body-sides);
    margin: 50px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.articlesPage .sectionHeadder {
    margin-bottom: 50px;
}

.articlesCont {
    max-width: 75%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    justify-content: space-around;
    gap: 80px;
    overflow: hidden;
}

.articleCard {
    width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: center;
    margin-bottom: 1rem; /*Added to Gap for a bit extra spacing*/ 
}

.articleCardImgCont{
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.articleTextSection {
    margin-bottom: 6rem;
    text-align: center;
}


.articleCardImg {
    width: 250px;
    height: 250px;
    margin-bottom: 1.25rem;
    background-color: var(--clr-main );
    border: 5px solid var(--clr-accent);
    border-radius: 300px;
    box-shadow: var(--shadow-img);
    object-fit: cover;

}

.articleCard h3 {
    margin-bottom: 1rem;
}

.articleCardText {
    width: 100%;
    margin-bottom: 1rem;
}

.lesMerBtn {
    /* justify-self: end; */
    margin-top: auto;
    align-self: flex-end;
}
.lesMerBtn:hover,
.lesMerBtn:focus {
    cursor: pointer;
    text-decoration: underline solid 2px var(--clr-accent);
}



/* __ Employee Modal __ */

.employeeModal {
    width: 100vw;
    height: 100vh;
    background-color: var(--bg-overlay);
    background-image: var(--bg-gradient);
    overflow: hidden;
    position: fixed;
    translate: 0 0;
    top: 0;
    left: 0;
}

.employeeModalCont{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.employeeModalCont img{
    width: 350px;
    height: 350px;
    margin-bottom: 2rem;
    background-color: var(--clr-main );
    border: 10px solid var(--clr-accent);
    border-radius: 300px;
    box-shadow: var(--shadow-img);
    object-fit: contain;
    object-position: top;
}

.employeeModalCont h3{
    margin-bottom: 1.125rem;
}

.employeeModalCont p{
    max-width:1200px;
    font-size: var(--fs-16);
}


/* .closeModal {
    font-size: 5rem;
    font-weight: 400;
    color: var(--clr-accent);
    scale: 0.85;
    position: absolute;
    translate: -50% 0;
    right: 40%;
}

.closeModal:hover, 
.closeModal:focus {
    cursor: pointer;
    scale: 1;
} */

.closeModal {
    font-size: 5rem;
    font-weight: 400;
    color: var(--clr-accent);
    scale: 0.85;
    position: fixed;
    top: 2%;
    right: 35%;
    cursor: pointer;
    text-shadow: 0px 0px 4px rgba(218, 231, 250, 0.1);
}

.closeModal:hover, 
.closeModal:focus {
    scale: 1;
}

.servicesPage {
    height: fit-content;
    width: 100%;
    padding: 0px var(--padding-body-sides);
    margin: 50px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.servicesPage h1.sectionHeadder {
    margin-bottom: 3rem;
}


.dentistCard h3 {
    font-size: var(--fs-18);
    font-weight: var(--fw-5);
    color: var(--fc);
}

.dentistCardText {
    font-size: var(--fs-18);
    font-weight: var(--fw-3);
    line-height: 1.25rem;
    color: var(--fc);
}

.dentistCard {
    width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: center;
    margin-bottom: 1rem; /*Added to Gap for a bit extra spacing*/ 
}

.dentistCardImgCont{
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
}


.dentistCardImg {
    width: 250px;
    height: 250px;
    margin-bottom: 1.25rem;
    background-color: var(--clr-main );
    border: 5px solid var(--clr-accent);
    border-radius: 300px;
    box-shadow: var(--shadow-img);
    object-fit: contain;

}


@media(max-width: 750px) {
    .articlesCont {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
        overflow: visible;
    }

    .articleCardImg{
        width: 200px;
        height: 200px;
    }
    .articleCardText {
        width: 250px;
        height: fit-content;
    }
    .lesMerBtn {
        align-self: center;
    }





            /* __ Employee Modal __ */

    .employeeModal {
        min-height: fit-content;
    }

    .employeeModalCont{
        padding: 0;
        padding: 2rem 1.125rem;
        justify-content: flex-start;
        overflow-y: scroll;
    }
    
    .employeeModalCont img{
        width: 250px;
        height: 250px;
        margin-bottom: 1rem;
        background-color: var(--clr-main );
        border: 5px solid var(--clr-accent);
    }
    
    .employeeModalCont h3{
        margin-bottom: 0.75rem;
    }    

    .employeeModalCont p {
        padding:0 1rem;
    }
    
    .closeModal {
        position: absolute;
        font-size: 3rem;
        top:2%;
        right: 10%;
        scale: 1.25; 

    }

    .employeeModalCont {
        top: 5%;
    }

    .closeModal:hover, 
    .closeModal:focus {
       scale: 1.75;
    }

    .serviceSection {
        font-size: 17px;   
        max-width: 100%; 
    }

    .hiddenText {
        padding-top: 2rem;
    }

    .servicesPage {
        margin-top: 50px;
    }


}