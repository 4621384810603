/* ___ TYPOGRAPHY ___ */

.omOssCont h1,
.omOssCont h2 {
  text-align: center;
}

.omOssCont p.omOssTekst {
  font-size: var(--fs-21);
  font-weight: var(--fw-4);
  letter-spacing: 0.01rem;
  line-height: 2 !important;
}

.management {
  display: flex;
  justify-content: center;
  /* padding-bottom: 50px; */
  /* align-content: center; */
}

.management:first-child {
  padding-top: 8rem;
}

.management:last-child {
  padding-top: 4rem;
}

.management img {
  width: 150px;
  height: 150px;
  object-fit: contain;
  border: 5px solid var(--clr-accent);
  border-radius: 360px;
}
.management div p {
  padding-top: 10px;
  font-size: var(--fs-21);
  padding-left: 3rem;
  width: 500px;
}
.management div h2 {
  font-size: var(--fs-28);
  padding-left: 3rem;
}

/* ___ DESIGN ___ */

.omOssCont {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 0 var(--padding-body-sides);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.omOssCont:first-child {
  margin-bottom: 5rem;
}

.omOssCont * {
  width: 800px;
  margin-bottom: 1.5rem;
}

.ansatteSection {
  height: 600px;
}

.leadershipSection, .ansatteSection2{
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start; */
  align-items: center;
  /*IMPORTANT need position relative for section headders Y offset*/
  position: relative;
  background-color: var(--clr-75opac);
  padding-bottom: 8rem;
}


.ansatteSection2 {
  padding-top: 8rem;
  height: 550px;
}

@media (max-width: 750px) {
  .omOssCont * {
    width: 80%;
  }

  .leadershipSection {
    padding-bottom: 0px;
    padding-top: 2rem;
  }

  .ansatteSection2 {
    height: 600px;
  }

  .management {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 100%;
  }

  .management div p {
    width: 100%;
    padding: 1rem 1rem;
  }

  .management div {
    padding-top: 2rem;
  }

  .management:first-child {
    padding-top: 3rem;
  }
  .management:last-child {
    padding-top: 3rem;
  }

  .management div h2 {
    padding-left: 0;
  }

  .ansatteSection {
    height: auto;
  }

  .ansatteSection2, .leadershipSection {
    padding-top: 5rem;
  }

  .ansatteSection .sectionHeadderPill {
    margin: 3rem 1rem;
  }

  .prev {
    left: 20px;
  }

  .next {
    right: 20px;
  }

  p.omOssTekst {
    font-size: var(--fs-17) !important;
  }
}
