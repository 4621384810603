
.articlePage {
    height: 100%;

    padding: 100px var(--padding-body-sides);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.exampleImage {
    width: fit-content;
    margin-bottom: 80px;
    border-radius: 20px;
    object-fit: contain;
    object-position: center;
    box-shadow: var(--shadow-img);
    border: 5px solid var(--clr-accent);
    background-color: var(--clr-accent-75opac);
    height:400px;
}

.articlePage h1 {
    margin-bottom: 80px;
}

.articleCont {
    height: fit-content;
    display: grid;
    gap:10%;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.articleInfoBox {
    /* height: auto; */
    max-height: 500px;
    width: fit-content;
    padding: 2.75rem 3rem;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--clr-75opac);
    border: 2px solid var(--clr-accent);
    border-radius: 8px;
    box-shadow: var(--shadow-bar);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.articleInfoBox h3 {
    font-size: var(--fs-18);
    font-weight: var(--fw-4);
    margin-bottom: 2rem;
}

.articleInfoBox ul li {
    margin-bottom: 2rem;
}

.articleInfoBox ul li img {
    margin-right: 1rem;

}

.articleInfoBox .btnBook {
    font-size: var(--fs-16);
    padding: 1rem 3rem;
}

.articlesTextCont p{
    padding: 10px 0px;
    line-height: 2;
 }


 .selectedArticle {
    margin: 10px 15px;
 }

 .deleteArticle {
    display: flex; 
    flex-direction: row;
    margin: 30px 0px;
    justify-content: end;
    width: 1000px;
}

.deleteButton{
    justify-content: end;
    border: 1px solid var(--clr-accent);
    background-color: var(--clr-accent-75opac);
    border-radius: 8px;
    width: 100px;
    text-align: center;
    margin-left: 30px;
    margin-right: 33%;
 }

 .deleteButton:hover {
    background-color: var(--clr-75opac);
    cursor:pointer;

 }

@media (max-width: 750px){
 .articleCont {
    display: flex;
    flex-direction: column;
 }

 .articleInfoBox {
    margin-top: 2rem;
 }

 .exampleImage {
    width: 100%;
    height: auto;
 }

 .deleteArticle {
    flex-direction: column;
    
    width: auto;
 }

 .deleteButton {
    margin-left: 0px;
    margin-right: 0px;
    margin: 10px 0px;
 }

 .selectedArticle{
    margin: 0px;
 }



}