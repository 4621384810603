.articlesReelCont {
    height: fit-content;
    width: 100%;
    padding: 8rem 1.125rem 0;
    display: grid;
    grid-template-columns: repeat(4, minmax(150px, 100%));
    gap: 1.125rem;
}




.articlesReelCard {
    width: 100%;
    height: fit-content;
    position: relative;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.articlesReelCard:hover,
.articlesReelCard:focus {
    animation: zoomArticleFrame;
    animation-fill-mode: forwards;
    animation-duration: 500ms;
    z-index: 60;
}

.reelImgCont {
    width: 100%;
    display: flex;
    justify-content: center;
}

.reelImgCont img {
    /* width: 250px;
    height: 250px; */
    width: min(18vw, 250px);
    aspect-ratio: 1/1;
    margin-bottom: 1.5rem;
    background-color: var(--clr-main );
    border: 6px solid var(--clr-accent);
    border-radius: 300px;
    box-shadow: var(--shadow-img);
    object-fit: cover;
    object-position: top;
}

:link {
    text-decoration: none;
}

.cardTitle{
    font-size: var(--fs-18);
    font-weight: var(--fw-5);
    text-decoration: none;
    text-align: center;
    letter-spacing: 1px;
    width: 90%;
    margin-bottom: 1rem;
}

.cardDesc {
    width: 80%;
    font-size: var(--fs-16);
    margin-bottom: 1rem;
}

.cardLesMer {
    font-size: var(--fs-16);
    font-weight: var(--fw-4);
    text-align: right;
    padding-right: 3.75rem;
    padding-top: 5px;
    text-decoration: underline;
    align-self: flex-end;
}




@keyframes zoomArticleFrame {
    from{
        scale: 1;
    }
    
    to{
        scale: 1.03;
    }
}

@media(max-width:900px) {
    .articlesReelCont {
        grid-template-columns: repeat(3, minmax(50px, 100%));

    }

    .reelImgCont img {
        width: 29vw;
    }

    .cardLesMer {
        padding-top: 30px;

    }
    
}

@media(max-width:700px) {
    .articlesReelCont {
        padding: 0 2.5rem;
        display: flex;
        flex-direction: column;
        gap:0;
    }

    .articlesReelCard {
        /* margin-bottom: 2rem; */
        height: fit-content;
    }

    .reelImgCont img {
        width: 50vw;
    }
    
    .articlesReelCard:first-of-type {
        margin-top: 3rem;
    }

    .articlesReelCard h3 {
        font-size: var(--fs-21);
    }

}