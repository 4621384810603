.createArticlePage {
    min-height: var(--page-height-noscroll);
    padding: 5rem  var(--padding-body-sides);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.createArticlePage .sectionHeadder {
    margin-bottom: 6rem;
}

#bloggEditor {
    width: 80%;
}

.text-editor {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.createArticleForm {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.createArticleForm input {
    min-width: 150px;
    font-size: var(--fs-18);
    padding: 0.75rem 0;
    padding-left: 1rem;
    margin-left: 2rem;
    margin-bottom: 2rem;

    border: 2px solid var(--clr-accent);
    border-radius: 100px;
}
.createArticleForm input:first-of-type {
    margin-left: 0;
    margin-right: auto;
}

.textEditorCont {
    width: 100%;
    background-color: var(--clr-main);
    margin-bottom: 2rem;
}

#createBlogButton {
    font-size: var(--fs-18);
}









/* _____ MEDIA QUERIE _____ */

@media(max-width: 750px) {
    #bloggEditor {
        width: 100%;
    }

    .createArticleForm {
        flex-direction: column;
    }


    .createArticleForm input {
        margin: 0px 0px 1rem;
    }

    .createArticleForm input:first-of-type {
        margin: 0px 0px 1rem;
    }


}