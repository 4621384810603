/* #cookieBannerBody {
  width: 100%;
  height: 100%;
  background-color: var(--clr-accent-75opac);

  display: grid;
  place-content: center;

  position: fixed;
  z-index: 1000;
  bottom:0;
  left:0;
}  */

.cookieBannerCont {
  height: fit-content;
  background-image: var(--bg-gradient);
  /* display: grid; */
  /* place-content: center; */
  box-shadow: var(--shadow-img);
  border: 3px solid var(--clr-accent);

  position: fixed;
  z-index: 1000;
  bottom:2rem;
  left:2rem;
}

.cookieCont {
  width: 350px;
  padding: 2rem;

  display: grid;
  grid-template-areas:
    "info info info"
    "deny empty accept";
  gap: 1rem;
}

.cb-text {
  grid-area: info;
  font-size: var(--fs-16);
}

/* .cb-input {
  justify-self: end;
  grid-area: input;
} */

.cb-btn {
  font-size: var(--fs-16);
  font-weight: var(--fw-4);
  text-shadow: var(--shadow-txt);
  text-decoration: none;
  width: fit-content;
  padding: 0.75rem 1.75rem;
  background-color: var(--clr-main);
  border: 3px solid var(--clr-accent);
  border-radius: 8px;
  cursor: pointer;
}
.cb-btn:hover,
.cb-btn:focus {
  background-color: var(--clr-accent-75opac);
  text-decoration: underline 3px solid var(--clr-main);
  border: 3px solid var(--clr-main);
  opacity: 90%;
}

.cb-btn.accept {
  grid-area: accept;
}
.cb-btn.deny {
  grid-area: deny;
}

@media (max-width: 750px) {
  .cookieBannerCont {
    /* width: max(200px, 100%); */
    left: 0;
  }

  .cookieCont {
    width: 100%;
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
  }
}
