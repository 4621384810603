.articlesPage .serviceHeadderCont .sectionHeadder{
    text-align: center;
    margin-bottom: 3rem;
}

.serviceHeadderCont {
    margin-bottom: 3rem;
}

.serviceHeadderCont p {
    font-size: var(--fs-18);
    font-weight: var(--fw-4);
}

.serviceItemCont p {
    font-size: var(--fs-18);
    font-weight: var(--fw-4);

    /* margin-left: 1.75rem; */
    margin-bottom: 0.5rem;
    padding-top: 1rem;
  }
.serviceItemCont {
    margin-top: 1rem;
}
.serviceSection {
    font-size: var(--fs-21);
    font-weight: var(--fw-4);
  
    width: max-content;
    padding: 0.5rem 1.25rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    
    background-color: var(--clr-main);
    border-radius: 100px;
    border: 2px solid var(--clr-accent);
    box-shadow: var(--shadow-btn);
}

.serviceItemCont::selection,
.serviceItemCont::selection {
    background-color: transparent;

}

.serviceItemCont:hover,
.serviceItemCont:focus {
    cursor: pointer;
}

.serviceIcon {
    display: inline-block;
    text-align: center;
    width: 10px;
}

.hiddenText {
    display: none;
}

.newSection {
    font-size: var(--fs-21)
}

.serviceHeadderCont .sectionHeadder {
    padding: 0rem;
}

.faqSection {
    max-width: 1200px;
}

.flexSection {
    display: flex;
    justify-content: space-between;
}

.arrowSection {
    font-size: 1.5rem;
    margin-right: 1rem;
}

h2.newSection::selection,
span.arrowSection::selection {
    background-color: transparent;
}
