.employeePage {
    min-height: var(--page-height-noscroll);
    padding: 8rem var(--padding-body-sides);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.employeeTrailCont {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4vw;
}

.employeeTrailCont * {
    text-decoration: underline 2px solid var(--clr-accent);
}
.employeeTrailCont *:hover, 
.employeeTrailCont *:focus {
    text-decoration: underline 3px dotted var(--clr-accent);
    scale: 1.2;
}

.employeePage h1{
    margin-bottom: 4rem;
}

.excelBtn {
    margin-bottom: 1.25rem;
    font-size: var(--fs-14);
}

.employeeCardTags {
    width: 100%;
    padding: 0 1.5rem;
    margin-bottom: 0.5rem;
    align-self: flex-start;
    display: flex;
}

.employeeCardTags * {
    width: 20%;
}

.employeeCardTags *:not(:first-child){
    text-align: center;
}

.employeeCardTags :last-child {
    text-align: end !important;
}

.employeePage ul{
    width: 100%;
}

.employeeCardCont {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    background-color: var(--clr-75opac);
    border-radius: 10px;
    border: 3px solid var(--clr-accent);
    box-shadow: var(--shadow-btn);
}

.empCardInnerCont {
    display: flex;
    flex-direction: row;
}

.empCardInnerCont.top{
    margin-bottom: 1.25rem;
}

.empCardInnerCont.top *{
    width: 20%;
}

.empCardInnerCont.top *:not(:first-child){
    text-align: center;
}

.empCardInnerCont.top :last-child {
    text-align: end;
}

.employeeCardbtn {
    margin-left: auto;
    width: 20%;
    text-align: end;
}

.employeeCardbtn {
    font-size: var(--fs-14);
    font-weight: var(--fw-6);
    text-transform: lowercase;
}
.employeeCardbtn:hover,
.employeeCardbtn:focus {
    cursor: pointer;
    text-decoration: underline 2px solid var(--clr-accent);
}

.cardBookings{
    display:grid;
    grid-template-columns: repeat(5, 1fr);
}

.employeeCardCont .cardBookings p {
    font-size: var(--fs-16);
}

.cardBookings :first-child, 
.cardBookings .bookingCardEmail {
    grid-column:span 2;
}

.bookingCardBottom {
    margin-top: 1.125rem;
}

#address{
    padding-right: 10px;
}

.illustrasjonsCont {
    width: 100%;
    padding: 1rem;
    border: none;
    background-color: rgba(245, 247, 250, 0.35);
}




.extraFields {
    display: none;
    grid-template-areas: 
        'beskrivelse beskrivelse beskrivelse'
        'fileUpload fileUpload endre';
    align-items: center;
    margin-top: 2.5rem;
}

.empCardFormCont {
    display: flex;
    flex-direction: column;
    gap:1rem;
    margin-bottom: 1.5rem;
}

.empCardFormCont.empDescription {
    grid-area: beskrivelse;
    height: 300px;
}

.empCardFormCont.empImg {
    grid-area: fileUpload;
}

.empCardFormCont.empEndre {
    grid-area: endre;
    margin-left: auto;
}

.empCardFormCont textarea.formInput {
    border-radius: 8px;
    height: 250px;
    font-family: 'railway';
    font-weight: var(--fs-18);
    letter-spacing: 115%;
}






@media(max-width:750px) { 
    .employeeCardTags {
        display: none;
    }   

    .illustrasjonsCont {
        width: fit-content;
    }
    
    .empCardInnerCont{
        flex-direction: column;
    }

    .empCardInnerCont.top {
        margin-bottom: 0px;
    }

    .empCardInnerCont.top *{
        text-align: start !important;
    }

    .empCardInnerCont.top :nth-child(1), 
    .empCardInnerCont.top :nth-child(2),
    .empCardInnerCont.top :nth-child(4) {
        order: 1;
    }

    .empCardInnerCont.top :nth-child(3), 
    .empCardInnerCont.top :nth-child(5) {
        order: 2;
    }

    .empCardInnerCont.top *,
    .empCardInnerCont.bottom *:not(.employeeCardbtn){
        width: 100%;
        margin-bottom: 0.25rem;
    }

    .empCardInnerCont.top :last-child {
        text-align: start;
    }

    .empCardInnerCont.bottom {
        flex-direction: column-reverse;
    }

    .employeeCardbtn {
        width: 100%;
        margin-left: 0px;
        text-align: start;
        margin-bottom: 1.25rem;
    }

    .cardBookings{
        display: flex;
        flex-direction: column;
    }
    .cardBookings p{
        margin-bottom: 1rem;
    }
    .bookingCardBottom {
        margin-top: 0;
    }

    .extraFields {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .extraFields *:not(:last-child) {
        width: 100%;
    }
}