/* __ TYPOGRAPHY __ */

.header .fullNavCont li{
    font-size: var(--fs-16);
    font-weight: var(--fw-4);
}

.btn-white {
    text-decoration: none;
}

.btn-white:hover,
.btn-white:focus {
    text-decoration: underline 2px solid var(--clr-accent);
}

.btn-white:active {
    text-decoration: none;
}


.mobileNavBtn {
    font-size: var(--fs-16);
    text-decoration: none;
    background-color: var(--clr-accent);
}

.mobileNavBtn:hover,
.mobileNavBtn:focus {
    text-decoration: underline solid 2px var(--clr-accent);
}


/* __ DESIGN __ */


.header {
    width: 100%;
    height: var(--navbar-height);
    box-shadow: var(--shadow-bar);
    background-color: var(--clr-75opac);
    /* display: ; */
}

.header .fullNavCont {
    height: 100%;
    width: 100%;
    padding-left: var(--padding-body-sides);
    padding-right: var(--padding-body-sides);
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    position: relative;
}

.header .fullNavCont li:not(:first-of-type):not(.mobileNavCont):not(.mobileNavCont li) {
    margin-left: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
}


.header-logo {
    margin-right:auto;
    justify-self: flex-start;
}

.header-logo :link {
    text-decoration: none;
    white-space: nowrap;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.fullNavCont .header-logo p.logoText{
    font-size: var(--fs-16);
    margin-left: 0.5rem;
    line-height: 1.4 !important;
}

.abra-Logo {
    max-width: clamp(80px, 100%, 150px);
    height: 100%;
}

.btn-white {
    padding: 10px 30px;
    background-color: var(--clr-main);
    border-radius: 100px;
    border: 3px solid var(--clr-accent);
    white-space: nowrap;
    cursor: pointer;
}

.btn-white:hover,
.btn-white:focus {
    outline: 1px dotted rgb(238, 142, 52);
}

.btn-white:active {
    background-color: var(--clr-accent);
    opacity: 100%;
}






/* __ BURGER MENU __  */

#MenuBtn {
    height: fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobileNavCont {
    width: 16rem;
    height: fit-content;
    margin-right: 3rem;
    background-image: var(--bg-gradient);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 4px 0px rgba(25, 32, 44, 0.05);

    position: absolute;
    z-index: 10;
    translate:0 0;
    right: 0;
    top:var(--navbar-height);
}


.mobileNavCont li {
    width: 100%;
}

.mobileNavBtn {
    width: 100%;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobileNavBtn:hover,
.mobileNavBtn:focus {
    background-color: var(--clr-main);
}

.mobileNavBookBtn {
    display: none;
}

.navBookBtnCont {
    display: block;
}



.menuDeco,
.menuDeco::before,
.menuDeco::after {
    height: 2px;
    width: 1rem;
    background-color: var(--clr-accent);
}

.menuDeco {
    position: relative;
    margin-right: 0.6rem;
}

.menuDeco::before,
.menuDeco::after {
    content: '';
    display: block;
    position: absolute;
}

.menuDeco::before {
    top: -4px;
}

.menuDeco::after {
    top: 4px;
}

.menuDecoAnim {
    animation: burgerCrossVanish 100ms linear forwards;
}

.menuDecoAnim::before {
    animation: burgerCrossPositive 100ms linear forwards;
}

.menuDecoAnim::after {
    animation: burgerCrossNegative 100ms linear forwards;
}


@keyframes burgerCrossVanish {
    100%{
        background-color: transparent;}
}
@keyframes burgerCrossPositive {
    0% {
        top: 0;
    }
    100%{
        top: 0;
        rotate:45deg;}
}
@keyframes burgerCrossNegative {
    0% {
        top: 0;
    }
    100%{
        top: 0;
        rotate:-45deg;}
}











@media (max-width:850px){    
    .header .fullNavCont li:not(:first-of-type) {
        margin-left: 0;
    }

    

    .header-logo :link {
        flex-direction: column;
        align-items: flex-start;
    }


}
@media (max-width:750px){   
    
    .mobileNavCont {
        width: 100%;
        margin-right: 0;
    }

    .mobileNavBookBtn {
        display: block;
    }

    .navBookBtnCont {
        display: none !important;
    }

    .header .fullNavCont li:not(:first-of-type):not(.mobileNavCont):not(.mobileNavCont li) {
        margin-left: 0;
    }

}
