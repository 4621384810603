/* __ DESIGN __ */

.wrapper {
  overflow: hidden;
  position: relative;
}

/* ~~~~ HERO ~~~~ */

.heroSection {
  height: fit-content;
  /* height: 30rem; */
  background-color: var(--clr-75opac);
  /* display: grid; */
  display: flex;

  /* grid-template-columns: minmax(400px, 3fr) minmax(200px, 2fr); */
}

.heroImg {
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.heroBooking {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.heroBookingInner {
  height: 100%;
  padding-left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}
.heroBookingInner button :link {
  white-space: nowrap;
}

.heroBookingInner h1 {
  margin-bottom: 60px;
  max-width: 350px;
}

.heroSlideCont {
  /* position: relative; */
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.heroSlideCont img {
  width: 60%;
  /* display: grid; */
  /* place-content: center; */
  /* flex-grow: 2; */
}

.heroTextCont {
  width: 100%;
  padding: 1.25rem 1.75rem;
  background-color: rgba(245, 247, 250, 0.75);
  box-shadow: 0px 4px 6px 1px var(--clr-accent);
  /* flex-grow: 1; */
  /* position: absolute; */
  /* bottom: 0; */
  /* overflow: hidden; */
}

.heroTextCont h1 {
  font-size: 48px;
  text-align: center;
  font-weight: var(--fw-6);
  margin-bottom: 0.75rem;
}

.heroTextCont p {
  font-size: 32px;
  font-weight: var(--fw-5);
}

.heroButton {
  position: absolute;
  top: 25%;
  right: 11%;
  /* margin-top: 4rem;
  margin-left: auto;
  margin-right: auto; */
}

/* ~~~~ WHY CHOOSE US ~~~~ */

.whyChooseUsSection {
  margin-top: 3rem;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 1rem;
  padding-bottom: 3rem;
}

.whyUsCol {
  width: var(--hero-width);
  display: flex;
  flex-direction: row;
  place-content: center;
}

.whyUsColInner {
  display: grid;
  grid-template-areas:
    "p1 p2 p3 p4"
    "i1 i2 i3 i4"
    "i1 i2 i3 i5";
  align-items: center;
  place-items: center;
  row-gap: 1rem;
  column-gap: 6rem;
}

.wuP1 {
  grid-area: p1;
}
.wuI1 {
  grid-area: i1;
}
.wuP2 {
  grid-area: p2;
}
.wuI2 {
  grid-area: i2;
}
.wuP3 {
  grid-area: p3;
}
.wuI3 {
  grid-area: i3;
}
.wuP4 {
  grid-area: p4;
}
.wuI4 {
  grid-area: i4;
}
.connectionCol p:first-child {
  margin-bottom: 23px;
}

.connectionCol {
  width: calc(100% - var(--hero-width));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.connectionCol p {
  text-align: center;
}

.connectIconCont {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

/* ~~~~ ANSATTE ~~~~ */

/* see "TannlegeReel.css" for reel design */
.ansatteSection {
  /* height: 550px; */
  width: 100%;
  background-color: var(--clr-75opac);
  padding: 8rem 0 1.5rem;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start; */
  align-items: center;
  /*IMPORTANT need position relative for section headders Y offset*/
  position: relative;
}

.ansatteTekst {
  max-width: 75%;
  text-align: center;
  margin-top: 4rem;
  line-height: 3;
}



/* ~~~~ HOW IT WORKS ~~~~ */

.howItWorksSection {
  width: 100%;
  padding: 20px var(--padding-body-sides) 100px var(--padding-body-sides);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.howItWorksSection h2 {
  margin-bottom: 3rem;
}

.howItWorksInfo {
  max-width: 700px;
  margin-bottom: 3rem;
}

.cardInfo {
  /* padding-top: 100px; */
  top: 100px;
  position: relative;
}


.contactInfo {
  top: 25px;
  position: relative;
}

.bookSessionInfo {
  top: -20px;
  position: relative;
}
.bookInfo {
  position: relative;
  top: 150px;
}

.stepByStepCont {
  max-width: 850px;
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: grid;
  grid-template-areas:
    "illu step1"
    "illu step2"
    "illu step3"
    "illu step4";
}

.brukIllu {
  width: 35%;
  height: fit-content;
  margin-right: 30px;
  place-self: end;
  /* margin-top: 50px; */
  padding-top: 50px;
  /* align-self: flex-end; */

  grid-area: illu;
}
.illuHorisontal {
  display: none;
}

.step {
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.step1 {
  grid-area: step1;
}
.step2 {
  grid-area: step2;
}
.step3 {
  grid-area: step3;
}
.step4 {
  grid-area: step4;
}

.stepNr {
  display: none;
}

.iconMobile {
  display: none;
}


.FaqSection {
  padding-top: 50px;
  padding-bottom: 50px;
}

.strong {
  font-weight: var(--fw-6);
}

p.howitWorksPitch {
  padding: 3rem 11rem 1.75rem;
  margin: 1.5rem;
  /* border-top: 1px solid var(--clr-accent); */
  border-bottom: 2px solid var(--clr-accent);
}

/* ~~~~ ARTICLES ~~~~ */

.articlesSection {
  height: fit-content;
  background-color: var(--clr-main);
  padding-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  /*IMPORTANT need position relative for section headders Y offset*/
  position: relative;
}

/* ~~~~ OM OSS ~~~~ */

.aboutUsSection {
  width: 100%;
  margin: 50px 0px 150px 0px;
  padding: 0 var(--padding-body-sides);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.aboutUsSection h2 {
  margin-bottom: 30px;
}

.aboutUsSection p {
  max-width: 900px;
  margin-bottom: 45px;
}

.loginActionsCont p {
  font-size: var(--fs-17);
}

p {
  font-size: var(--fs-21);
}

/* ~~~~ SAMARBEIDSPARTNERE ~~~~ */

.partnersSection {
  width: 100%;
  height: 300px;
  background-color: var(--clr-75opac);
  display: grid;
  place-items: center;
  /*IMPORTANT need position relative for section headders Y offset*/
  position: relative;
}

.imagesPartners {
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
  padding-top: 50px;
  height: 50%;
  justify-content: center;
}

.imagesPartners img {
  height: 50px;
  margin: 1rem;
}

/* ~~~~ CONTACT ~~~~ */

.contactUsSection {
  padding: 0 var(--padding-body-sides) 0;
}

.contactUsCont {
  margin: 0px 0 100px;
  display: grid;
  grid-template-columns: minmax(450px, 40vw);
  align-items: center;
  justify-content: center;
}

#message {
  margin-top: 20px;
}

.contactUsCont h1 {
  margin-bottom: 40px;
}

.contactUsCont form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contactUsCont form label {
  margin-bottom: 18px;
}
.contactUsCont form input[type="text"],
.contactUsCont form textarea {
  height: 45px;
  padding-left: 15px;
  margin-bottom: 40px;
  background-color: var(--clr-main);
  border: 3px solid var(--clr-accent);
  border-radius: 8px;
}

.contactUsCont form textarea {
  height: 120px;
}

.contactUsCont form .btnBook {
  max-width: fit-content;
}

.chatIcon {
  height: 50px;
  position: fixed;
  bottom: 5%;
  right: 5%;
  filter: drop-shadow(5px 5px var(--clr-accent-35opac));
  cursor: pointer;
}

/* _______ MEDIA QUERY _______ */

@media (max-width: 1500px) {

  .heroTextCont {
    padding: 1rem 1.75rem;
  }

  .heroTextCont h1 {
    font-size: 40px;
    text-align: center;
    font-weight: var(--fw-6);
    margin-bottom: 0.75rem;
  }
  
  .heroTextCont p {
    font-size: 28px;
    font-weight: var(--fw-5);
  }
  
  .heroButton {
    top: 27%;
  }

  p.howitWorksPitch {
    padding: 3rem 6rem 1.75rem;
  }
  
}
@media (max-width: 750px) {
  /* ~~~~ HERO ~~~~ */
  .heroSection {
    display: flex;
    flex-direction: column;
  }

  .heroBooking {
    padding: 5rem 1rem;
    align-items: center;
  }

  .heroBooking h1 {
    text-align: center;
    margin-bottom: 60px;
    max-width: 350px;
  }

  /* ~~~~ WHY CHOOSE US ~~~~ */
  .whyChooseUsSection {
    height: fit-content;
    flex-direction: column;
    align-items: center;
  }

  .whyUsCol {
    width: 100%;
  }

  .whyUsCol {
    flex-direction: column;
    padding-bottom: 0;
  }

  .whyUsColInner {
    column-gap: 1rem;
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
  }

  .connectionCol {
    width: 100%;
  }

  /* ~~~~ ANSATTE ~~~~ */
  .ansatteSection {
    padding: 1rem 0;
    justify-content: flex-start;
    flex-direction: column;
  }

  .ansatteSection .sectionHeadderPill {
    margin: 1rem 1rem;
  }

  /* ~~~~ HOW IT WORKS ~~~~ */

  .stepByStepCont {
    display: flex;
    flex-direction: column;
  }

  .stepByStepCont * {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .step {
    align-self: flex-start;
  }

  .brukIllu {
    width: 100%;
    order: 5;
  }
  .illuHorisontal {
    display: block;
  }
  .illuVertikal {
    display: none;
  }

  p.howitWorksPitch {
    padding: 3rem 0rem 1.75rem;
  }

  /* ~~~~ ARTICLES ~~~~ */
  .articlesSection {
    padding: 1rem 0;
    justify-content: flex-start;
    flex-direction: column;
  }

  /* ~~~~ OM OSS ~~~~ */
  .aboutUsSection {
    margin: 100px 0 175px;
  }

  /* ~~~~ SAMARBEIDSPARTNERE ~~~~ */
  .partnersSection {
    height: fit-content;
    padding: 4rem 1.5rem;
  }

  .imagesPartners {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .imagesPartners img {
    margin: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* ~~~~ CONTACT ~~~~ */
  .contactUsCont {
    margin: 50px 0 175px;
    grid-template-columns: 80vw;
  }


  .heroSlideCont {
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* position:absolute */
  }

  .heroTextCont {
    position: relative;
    display: flex;
    flex-wrap: wrap;
      justify-content: center;

  }

  .heroTextCont h4 {
    font-size: var(--fs-21);
    text-align: center;
  }

  .heroTextCont p {
    font-size: var(--fs-18);
  }

  .heroBookingInner {
    padding-left: 0%;
  }

  .stepNr {
    display: none;
    font-size: 50px !important;
    font-weight: var(--fw-6) !important;
    color: #97c8ed !important;
    margin-right: 20px;
    text-shadow: 0 0 5px #ffffff;
  }

  .iconMobile {
    display: inline;
    width: 100px;
    padding-right: 20px;
  }

  .howItWorksSection,
  .articlesSection,
  .ansatteSection {
    padding-bottom: 100px;
  }

  .heroSlideCont img {
    width: 100%;
  }

  .heroButton {
    position: relative;
    margin-top: 20px;
  
    /* display: inline-block; */
    top: 0;
    right: 0;
  }

  .heroSection {
    margin-bottom: 20px;
  }
}
