
        /* __ TYPOGRAPHY __ */

.formLabel,
.formInput,
.formInput ::placeholder {
    font-size: var(--fs-16);
    font-weight: var(--fw-5);
    color: var(--fc);
}

.formBtn {
    font-size: var(--fs-21);
    font-weight: var(--fw-4);
    letter-spacing: var(--ls-4);
    color: var(--fc);
}

.formDateCont p {
    font-size: var(--fs-14);
    font-weight: var(--fw-4);
    color: var(--fc);
    opacity: 80%;
    outline: none;
}
.bookNewDateLink {
    outline: none;
    text-decoration: none;
}
.infoButton {
    padding-left: 20px;
}
.infoButton img {
    width: 20px;
    height: 20px;

}



        /* __ ENKELT ELEMENTER __ */
.formLabel {
    margin-bottom: 0.5rem;
}

.formInput {
    width: 100%;
    padding: 15px;
    margin-bottom: 1.25rem;
    background-color: var(--clr-main);
    border-radius: 50px;
    border: 3px solid var(--clr-accent);
    box-shadow: var(--shadow-btn);

}


.formTimeCont {
    width: 100%;
    height: 350px;
    margin-bottom: 40px;
    background-color: var(--clr-main);
    border-radius: 8px;
    border: 3px solid var(--clr-accent);

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}


.formTimeCont .number{
    width: 50%;
    height: 34px;
    display: grid;
    place-content: center;
}

.formTimeCont .number:hover,
.formTimeCont .number:focus{
    background-color: var(--clr-accent);
    cursor: pointer;
}

#feedback {
    display: inline;
}


.formBtn {
    width: 100%;
    height: 65px;
    background-color: var(--clr-accent);
    border: none;
    border-radius: 40px;
    cursor: pointer;
}

.formBtn:hover,
.formBtn:focus {
    background-color: var(--clr-75opac);
    border: 4px solid var(--clr-accent);
    text-decoration: underline 2px solid var(--clr-accent);
}

.mobileBtn {
    display: none;
}

.form_error {
    border: 3px solid var(--clr-error);
}
.form_error::placeholder {
    color: var(--clr-error);
}


        /* __ LAYOUT BOOKING PAGE __ */


.bookingPage {
    width: 100%;
    min-height: var(--page-height-noscroll);
    padding:100px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}


.formCont {
    width: 65%;
    display: grid;
    gap:10%;
    grid-template-columns: repeat(2, minmax(200px, 1fr));
}

.formSide {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.formInputCont {
    display: flex;
    flex-direction: column;
}

.selectedTime {
    background-color: var(--clr-accent-75opac);
}

.formDateCont {
    display: flex;
    flex-direction: column;
    justify-self: flex-start;
    margin-bottom: auto;
}

.formDateCont .formInput {
    margin-bottom: 10px;
}

.formDateCont p {
    margin-bottom: 50px;
}

span.infoButton {
    position: relative;
}

#idMessage, #idMessage2{    
    width: 30vw;
    border: 1px solid var(--clr-accent);
    border-radius: 3px;
    background-color: var(--clr-main);
    box-shadow: var(--shadow-btn);
    padding:0.5rem 0.75rem;

    display: none;
    position: absolute;
    bottom:25px;
    left:0;
}

#idMessage2 {
    left: 5;
    margin-left: 58px;
}

#idMessage p {
    font-size: var(--fs-16);
}

#idMessage2 p{
    font-size: var(--fs-16);

}

/* Booking > Payment Modal */

.checkoutModal {
    height: 100%;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: var(--bg-overlay);
    backdrop-filter: blur(10px);
}

.checkoutModalInner {
    width: 30vw;
}

.paymentBtn {
    margin-top: 3rem;
}

#closePayment {
    font-size: 5rem;
    font-weight: 400;
    color: var(--clr-accent);
    scale: 0.85;
    position: fixed;
    top: 20%;
    right: 30%;
    cursor: pointer;
    text-shadow: 0px 0px 4px rgba(218, 231, 250, 0.1);
}

#closePayment:hover, 
#closePayment:focus {
    scale: 1;
}

.feedbackBooking {
    font-size: var(--fs-18);
    margin: 3.5rem 0 0;
    max-width: 50%;
    line-height: 24px;
    text-shadow: var(--shadow-txt);
}


        /* __ LOGIN / NEW USER __ */

.loginPage {
    width: 100%;
    height: var(--page-height-noscroll);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.loginPageCont {
    height: fit-content;
    margin-top: 1rem;
    display: grid;
    gap:10%;
    grid-template-columns: repeat(1, minmax(200px, 450px));
    align-items: center;
    justify-content: center;
}

.loginPageCont h1 {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

#login_form input:not(.formBtn) {
    margin-bottom: 30px;
}

.loginActions {
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.loginActionsCont {
    display: flex;
    flex-direction: column;
}

.loginActionsCont p:first-of-type {
    margin-bottom: auto;
}
.loginActionsCont * {
    text-decoration: none;
}
.loginActionsCont *:hover, 
.loginActionsCont *:focus {
    text-decoration: underline;
}

.loginActions .formBtn {
    font-size: var(--fs-16);
    height: 50px;
}





.createUserPage {
    width: 100%;
    min-height: var(--page-height-noscroll);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.createUserPageCont {
    margin-top: 100px;
    margin-bottom: 130px;

    display: grid;
    gap:10%;
    grid-template-columns: repeat(1, minmax(200px, 500px));
    align-items: center;
    justify-content: center;
}


.createUserPageCont .formSide {
    align-items: center;
}

.createUserPageCont .formSide h1 {
    margin-bottom: 45px;
}

.formSpacer {
    margin-bottom: 100px;
}

#showCalendar {
    width: auto;
    height: auto;
    background-color: white;
    visibility: hidden;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
}



#cancelledBooking {
    font-size: var(--fs-18);
    font-weight: var(--fw-4);
    /* color: rgb(228, 16, 24); */
    color: var(--fc);
    background-color:var(--clr-main) ;
    padding:0.5rem 1rem;
    border: 2px solid rgba(228, 16, 23, 0.3);
    border-radius: 4px;
    
    position: absolute;
    translate:-50% 0;
    bottom: 5%;
    left:50%;
}

.formError {
    border-color:red;
    color:red;
}
.formError::placeholder{
    color:red;
}


#errorMessage {
    font-size: var(--fs-18);
    font-weight: var(--fw-4);
    /* color: rgb(228, 16, 24); */
    color: red;
    /* background-color:var(--clr-main) ; */
    /* padding:0.5rem 1rem; */
    /* border: 2px solid rgba(228, 16, 23, 0.3); */
    border-radius: 4px;
    
    position: absolute;
    translate:-50% 0;
    bottom: 22%;
    left:68%;
    
}

/* _______ MEDIA QUERY _______ */

@media (max-width:750px) {
  
  
            /* ~~~~ ENKELT ELEMENTER ~~~~ */
    .mobileBtn {
        display: block;
        order:3;
    }
    .desktopBtn {
        display: none;
    }



            /* ~~~~ LAYOUT BOOKING PAGE ~~~~ */
    .formCont {
        grid-template-columns: repeat(1, minmax(200px, 1fr));
        gap:2rem;
    }

    .left { order: 2;}
    .right { order: 1;}
    
        /* Booking > Payment Modal */
    .checkoutModal {
        padding: 8rem 2rem 0;
        justify-content: flex-start;

    }

    .checkoutModalInner {
        width: 100%;
        top: 1;
    }

    .closePayment {
        position: static;
    }

    #idMessage, #idMessage2 {    
        width: 80vw;
        left:-150px;
    }



            /* ~~~~ LOGIN / NEW USER ~~~~ */

    .loginPage {
        height: 50vh;
        padding: 0 2rem;
    }

  
            /* Booking > Payment Modal */

    #cancelledBooking {
        bottom: 2%;
    }

    #closePayment {
        top: 2%;
        right: 4%;
    
    }

    .heroBookingInner button {
        margin-left: auto;
        margin-right: auto;
    }

    #errorMessage {
        color: red;
        margin-left: 40%;
        margin-right: 40%;
        position: absolute;
        bottom: 32%;
    }
            
}