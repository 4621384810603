
.helpSectionPage {
    height: 100%;
    width: 100%;
    padding: 50px var(--padding-body-sides);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.helpSectionPage h1 {
    margin-bottom: 4.5rem;
}
.helpSectionPage h2 {
    margin-bottom: 1.2rem;
    font-size: var(--fs-21);
}

.helpSectionPage h3 {
    margin-bottom: 1.2rem;
    font-size: var(--fs-21); 
}

h2.seperateHeadder {
    margin-bottom: 3rem;
}

.helpSectionPage p {
    font-size: var(--fs-18);
    font-weight: var(--fw-3);
    line-height: 2;
    color: var(--fc);

    max-width: 50%;
    margin-bottom: 2rem;
}

.helpSectionPage ul {
    font-size: var(--fs-18);
    font-weight: var(--fw-3);
    line-height: 2;
    color: var(--fc);

    width: max(250px, 50%);
}


.helpSectionPage ul li{
    font-size: var(--fs-18);
    font-weight: var(--fw-3);
    line-height: 2;
    color: var(--fc);

    margin-bottom: 2rem;
    list-style: circle;
}



.tinyLogo{
    width: 20px;
    margin-bottom: 4rem;
    
}

.lastFAQ{
    margin-bottom: 5rem;
}

.faqQuickfire li {
    list-style: none !important;
    text-align: center;
    margin-bottom: 5rem !important;
}


@media (max-width: 750px) {
    .helpSectionPage h3 {
        text-align: center;
    }

    .helpSectionPage p {
        max-width: 100%;
        /* margin: 0px 30px; */
    }
    
    .helpSectionPage ul {
        width: max(250px, 80%);
    }
}