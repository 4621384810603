/* __ COMPONENTS __ */

/* Hides the item for regular users, still functions for screen readers */
.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

/* ~~~~ Body ~~~~ */

body {
  background-color: var(--bg-solid); /*backup for gradient*/
  background-image: var(--bg-gradient);
  background-repeat: no-repeat;
  height: 100%;
}

/* ~~~~ BTNs ~~~~ */

.btnBook {
  /*booking btn*/
  font-size: var(--fs-21);
  font-weight: var(--fw-4);
  text-shadow: var(--shadow-txt);
  text-decoration: none;

  padding: 1rem 2.5rem; 
  background-color: var(--clr-accent);
  border-radius: 8px;
  border: none;
  box-shadow: var(--shadow-btn);
  cursor: pointer;
}

.btnBook:hover,
.btnBook:focus {
  background-color: var(--clr-accent-75opac);
  outline: 1px dotted rgb(238, 142, 52);
}

.btnBook:active {
  background-color: var(--clr-accent);
  outline: none;
}

.btnReadMore {
  font-size: var(--fs-17);
  font-weight: var(--fw-4);
  text-shadow: var(--shadow-txt);

  padding: 10px 40px;
  background: var(--clr-main);
  border: 3px solid var(--clr-accent);
  border-radius: 40px;
  cursor: pointer;
}

.btnReadMore:hover,
.btnReadMore:focus {
  background-color: var(--clr-accent-75opac);
  outline: 1px dotted rgb(238, 142, 52);
  text-decoration: 2px solid var(--clr-main);
}

.btnReadMore:active {
  background-color: var(--clr-accent);
  outline: none;
}

.btnReadMore :link {
  text-decoration: none;
}

/* ~~~~ HEADDERS ~~~~ */


.sectionHeadderPill {
  font-size: var(--fs-33);
  font-weight: var(--fw-1);

  width: max-content;
  padding: 10px 80px;
  background-color: var(--clr-main);
  border-radius: 100px;
  border: 3px solid var(--clr-accent);
  box-shadow: var(--shadow-btn);
  /*IMPORTANT offsets header by 50% of the top - give container position: Relative;*/
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.sectionHeadderPill:hover {
  cursor: pointer;
  background-color: var(--clr-75opac);
  border: 3px solid var(--clr-accent-75opac);
  box-shadow: 0px 0px 4px rgba(25, 25, 27, 0.25);
}

@media (max-width: 750px) {
  .sectionHeadderPill {
    /* padding: 0; */
    /* background-color: transparent; */
    /* border-radius: 0; */
    /* border: none; */
    /* box-shadow: none; */
    position: static;
    transform: none;
  }
}
