/* For controlling typography Landing Page */

/* All fonts set in Reset.css - Raleway, san-serif */

/* ~~ GLOBAL COMP ~~ */

.sectionHeadder {
  font-size: var(--fs-33);
  font-weight: var(--fw-1);
  letter-spacing: var(--ls-4);
  color: var(--fc-black);
}

.inbetweenSectionHeadder {
  font-size: var(--fs-28);
  font-weight: var(--fw-3);
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
}

/* HERO */
.heroBooking h1 {
  font-size: var(--fs-28);
  font-weight: var(--fw-3);
  color: var(--fc-black);
  line-height: 36px;
  letter-spacing: var(--ls-4);
}

/* WHY CHOOSE US */

.whyUsCol p,
.connectionCol p {
  font-size: var(--fs-21);
  font-weight: var(--fw-2);
  line-height: 25px;
  letter-spacing: var(--ls-4);
  color: var(--fc-black);
  text-align: center;
}

/* WHY CHOOSE US */
.ansatteSection p {
  line-height: 1.125rem;
}
.ansatteSection .ansatteTekst {
  font-size: var(--fs-21);
  line-height: 1.5rem;
}

/* PARTNERS */

/* HOW IT WORKS */

.howItWorksInfo {
  text-align: center;
}

.howItWorksSection p {
  font-size: var(--fs-21);
  font-weight: var(--fw-4);
  /* letter-spacing: var(--ls-4); */
  color: var(--fc);
}

.howItWorksSection .btnBookWhyUs {
  padding: 15px 0;
  background-color: transparent !important;
  /* margin-top: 100px; */
}


.btnBookWhyUs2 {
  padding: 15px 0;
  background-color: transparent !important;
  margin-top: 150px;
}

p.howitWorksPitch {
  font-size: var(--fs-28);
  font-weight: var(--fw-3);
  text-align: center;
}

/* ~~~~ ABOUT US ~~~~ */

.aboutUsSection p {
  font-size: var(--fs-21);
  font-weight: var(--fw-4);
  text-align: center;
  /* letter-spacing: var(--ls-4); */
}

/* ~~~~ CONTACT ~~~~ */

.contactUsCont form label {
  font-size: var(--fs-17);
  font-weight: var(--fw-5);
  color: var(--fc);
  opacity: 60%;
}

.contactUsCont form input[type="submit"] {
  font-size: var(--fs-16);
  color: var(--fc);
}

h1,
h2,
h3,
p,
:link,
a {
  line-height: 2 !important;
}


@media(max-width: 750px) {
        h1 {
                line-height: 1.5 !important;
        }

        .ansatteSection .ansatteTekst {
                margin-top: 2rem;
                
                /* margin-bottom: 2rem; */
        }
        p.howitWorksPitch {
                font-size: 22px;
        }

        .FaqSection {
                text-align: center;
        }
}