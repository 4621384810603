:root {
    /* __ TYPO __ */

/* Font Size (px) */
--fs-14: 0.875rem;
--fs-16: 1rem;
--fs-18: 1.125rem;
--fs-21: 1.313rem; /*vurderer skifte til 1.125rem*/
--fs-28: 1.75rem;
--fs-33: 2.063rem;
--fs-44: 2.75rem; 


/* Font Weight */
--fw-1: 200;
--fw-3: 300;
--fw-4: 400;
--fw-5: 500;
--fw-6: 600;

/* Letter Spacing - number refers to figma % */
--ls-4: 0.03em; 

    /* __ COLOURS __ */

/* Font Color/ BackGround/ Main / Color main+accent / BoXshadow */
--fc: #19191B;
--fc-darkmode:#F5F7FA; 
--fc-black:#000000;
--fc-placeholder: rgba(25, 25, 27, 0.5);
--fc-taken: #3c56693c;

--clr-main: #f5f7fa;
--clr-accent: #A2CFF1;
--clr-accent-75opac: #a2cff1bf;
--clr-accent-35opac: #a2cff13c;
--clr-taken: #3c56693c;
--clr-75opac: rgba(245, 247, 250, 0.65);
--clr-error: rgba(232, 105, 105, 0.979);

--bg-solid: rgb(220,237,248);
--bg-overlay: rgba(245, 247, 250, 0.20);
--bg-darkmode: #000;
--bg-gradient: linear-gradient(180deg, rgba(220,237,248,1) 0%, rgba(231,242,245,1) 100%);

--shadow-btn: 0px 0px 4px rgba(25, 25, 27, 0.15);
--shadow-txt: 0px 0px 4px rgba(245, 247, 250, 0.2);
--shadow-img: 0px 4px 6px 1px rgba(25, 32, 44, 0.15);
--shadow-bar: 0px 0px 3px rgba(25, 25, 27, 0.05);


    /* __ SIZES __ */

--navbar-height: 135px;
--footer-height: 250px;
--page-height-noscroll: calc(100vh - var(--navbar-height) - var(--footer-height));

--padding-body-sides: 8%; /*for consistent padding on all sections*/
--hero-width:60%; /*Used (combine with calc) to get consistent width of Hero section and WhyChooseUs*/

}


@media (max-width: 1000px){
    :root {
            /* Font Size (px) */
        --fs-14: 0.750rem;
        --fs-16: 0.875rem;
        --fs-18: 1rem;
        --fs-21: 1.125rem; /*vurderer skifte til 1.125rem*/
        --fs-33: 1.313rem;
        --fs-44: 2rem;  
    }
}


@media (max-width: 750px){
    :root {
            /* __ SIZES __ */

        --navbar-height: 90px;
        --footer-height: fit-content;
        --page-height-noscroll: fit-content;

    }
}

@media (max-width: 500px){
    :root {
            /* __ SIZES __ */

        --padding-body-sides: 1.125rem; /*for consistent padding on all sections*/

    }
}