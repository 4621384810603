/* __ TYPOGRAPHY __ */

.footerLogo {
    text-decoration: none;
}

.footerLink {
    font-size: var(--fs-16);
    font-weight: var(--fw-4);
    color: var(--fc);
    text-decoration: none;
}

.footerLink:hover,
.footerLink:focus {
    font-weight: var(--fw-5);
    text-decoration: underline 2px solid var(--clr-accent);
}

.footerLogo :link{
    text-decoration: none;
}




/* __ DESIGN __ */


.footer {
    width: 100%;
    height: var(--footer-height);
    padding: 0 var(--padding-body-sides);
    background-color: var(--clr-75opac);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footerCont {
    width: 100%;
    height: 150px;

    display: flex;
    align-items: left;
    flex-direction: column;
    flex-wrap: wrap;
}

.loggedInIndikator {
    width: 100%;
    display: grid;
    place-items: center;
    margin-bottom: 2rem;
}


.footerCont li{
    height: 33%;
    display: flex;
    align-items: center;
}
.footerCont li :link {
    max-width: 150px;
    white-space: nowrap;
}

.footerCont .footerLogo {
    height: 100%;
    display: flex;
    align-items: center;
}

.footerLogo svg {
    width: 10rem;
    height: fit-content;
    margin-bottom: 0.50rem;
}

/* .footerLogo img {
    width: 8rem;
    margin-bottom: 0.25rem;
} */




@media (max-width:750px) {

    .footer {
        /* text-align: center; */
        align-content: center;

    }
    .footerCont {
        height: fit-content;
        padding: 3rem 0;
        text-align: center;
        /* justify-content: center; */
        align-items: center;
        /* justify-items: center; */
    }

    .footerCont li {
        margin-bottom: 2rem;
    }

    .footerLogo p {
        margin-bottom: 2rem;
    }
}